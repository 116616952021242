import React, { useState, useEffect } from 'react';
import { MobileNav } from './mobileNav';
import { Bars4Icon } from '@heroicons/react/24/solid';
import { cn } from '../utils/tw';

import { useTranslation } from "react-i18next";
import { Oval } from "react-loader-spinner";

export const Navigation = (props) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [isLanguageChange, setIsLanguageChange] = useState(false);
  const { i18n } = useTranslation()

  const NAV_ITEMS = props.data ? props.data : {};

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      if (currentPosition > 200) {
        setIsVisible(false);
        setIsScrolled(true);
      } else {
        setIsVisible(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('SITE_LANGUAGE', lng)
    setIsLanguageChange(true)
    setTimeout(() => {
      setIsLanguageChange(false);
    }, 500);
  };

  return (
    <div id="menu" role="navigation" className="sticky left-0 right-0 top-0 z-10 h-[100px] bg-[#F8F9FA]">
      <div className="container relative h-full ">
        {(
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform md:top-1/3 md:-translate-y-1/3 ">
            <a className="navbar-brand" href="/">
              <img src="img/fansi.png" alt="Fansi Corp"
                className={`${isVisible && isScrolled ? "w-[100px] md:w-[200px] animate-in duration-150 slide-in-from-top"
                  : cn('w-[100px] md:w-[200px]', `${isScrolled ? '-translate-y-10 duration-150 opacity-0' : ''}`)}`} />
            </a>
          </div>
        )}
        <button className="absolute top-1/2 block -translate-y-1/2 md:hidden" onClick={() => setOpenMobileNav(true)}> <Bars4Icon className="h-6 w-6" /></button>

        {(
          <a className="relative left-1/2 top-1/2 block w-[34px] -translate-x-1/2 -translate-y-1/2 transform duration-150 md:left-0 md:w-[42px] md:translate-x-0 lg:w-[68px]"
            href="/">
            <img src="img/fansi-icon.png" alt="Fansi Corp"
              className={`${!isVisible ? "animate-in duration-150  slide-in-from-bottom" : "translate-y-10 duration-150 opacity-0"}`} />
          </a>
        )}

        <div className={cn('absolute left-1/2 hidden w-fit -translate-x-1/2 md:flex duration-200 slide-in-from-top', {
          'bottom-2': isVisible,
          'top-1/2 -translate-y-1/2': !isVisible
        },)}
        >
          <ul className="mx-auto flex gap-8 ">
            {NAV_ITEMS.map((item) => (
              <li key={item.path} className={`${!isVisible ? "text-xl text-[#000000a6] hover:text-[#000000cc] animate-in duration-200 slide-in-from-bottom"
                : "text-xl text-[#000000a6] hover:text-[#000000cc] "}`} >
                <a href={item.path} className="whitespace-nowrap capitalize">
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className={`${!isVisible ? "absolute top-10 right-6 md:top-10 md:right-8  animate-in duration-200 slide-in-from-bottom"
          : "absolute top-10 right-6 md:top-16 md:right-8 animate-in duration-200 slide-in-from-top"}`}>
          <ul className="mx-auto flex text-sm md:text-base ">
            <li onClick={() => changeLanguage('en')} className="text-[#000000a6] cursor-pointer pr-2" > EN  </li> |
            <li onClick={() => changeLanguage('vi')} className="text-[#000000a6] cursor-pointer pl-2"> VI </li>
          </ul>
        </div>
      </div >

      <MobileNav items={NAV_ITEMS} open={openMobileNav} onClose={() => setOpenMobileNav(false)} />

      <div className={`${isLanguageChange ? "flex justify-center items-center h-screen fixed top-0 left-0 right-0 bottom-0 w-full z-50 overflow-hidden bg-white opacity-80" : ""}`}>
        <Oval
          visible={isLanguageChange}
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    </div>
  );
};
