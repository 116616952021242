import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import HOME_EN from "./locales/en/homePage.json";
import HOME_VI from "./locales/vi/homePage.json";

export const locales = {
    en: 'EN',
    vi: 'VN'
};

const resources = {
    en: {
        homePage: HOME_EN,
    },
    vi: {
        homePage: HOME_VI
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",
        ns: ['homePage'],
        defaultNS: 'homePage',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;