import React from 'react';
import { Breadcrumb } from './breadcrumb';
import { Navigation } from './navigation';
import { BottomSection } from './bottomSection';

export const About = (props) => {
  const mainPageImg = "img/about/about-team.png";
  const whoImg = "img/about/about-us-who.jpg";
  const brandsImg = "img/about/about-us-brand.jpg";

  const aboutData = props.data ? props.data : {}
  const navData = props.navData ? props.navData : [];

  return (
    <div>
      <Navigation data={navData} />

      <div id="about-us-header" className="">
        <img className="hero" src={mainPageImg} alt="Fansi Group" />
      </div>

      <div id="about" className="pb-12">
        <div className="container">
          <Breadcrumb data={navData} />

          <div className="grid grid-cols-1 gap-6 
                          md:grid-cols-2 
                          pt-5 md:pt-10 lg:pt-10 
                          pb-5 md:pb-10 lg:pb-10">
            <div className="text-justify uppercase">
              <h2 className="mb-5 text-4xl font-bold text-[#333]">{aboutData.AboutTitle}</h2>
              <h3 className="mb-5 text-2xl font-semibold text-[#333]">{aboutData.AboutHeadLine}</h3>
            </div>
            <div className="text-justify">
              <h6>
                {aboutData.AboutContent1}
              </h6><br />
              <h6>
                {aboutData.AboutContent2}
              </h6><br />
              <h6>
                {aboutData.AboutContent3}
              </h6>

            </div>
          </div>

          <div className="pt-12">
            <div className="grid gap-6 md:grid-cols-2">
              <div className="columns-1">
                <h2 className="section-title-h2 uppercase">{aboutData.AboutHeadLine1}</h2>
                <div>
                  <img src={whoImg} className="h-[265px] w-full md:h-[380px] lg:h-[375px]" alt="" />
                </div>
                <div className="uppercase">
                  <ul className="mt-6 md:mt-3 lg:mt-6">
                    <li className="text-center md:text-left">
                      <a href="/creations" rel="nofollow" className="text-black underline hover:underline">
                        {aboutData.CreationLink}
                      </a>
                    </li>
                    <li className="text-center md:text-left">
                      <a href="/careers" className="text-black underline hover:underline">
                        {aboutData.CareersLink}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="columns-1">
                <h2 className="section-title-h2 uppercase">{aboutData.AboutHeadLine2}</h2>
                <div>
                  <img src={brandsImg} className="h-[265px] w-full md:h-[380px] lg:h-[375px]" alt="" />
                </div>
                <div className="uppercase">
                  <ul className="mt-6 md:mt-3 lg:mt-6">
                    <li className="text-center md:text-left">
                      <a href="/brands/beinbe" rel="nofollow" className="text-black underline hover:underline">
                        {aboutData.BeinBeLink}
                      </a>
                    </li>
                    <li className="text-center md:text-left">
                      <a href="/brands/buddy" className="text-black underline hover:underline">
                        {aboutData.BuddyLink}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomSection navData={navData} contactInfo={props.contactInfo} />
    </div>
  );
};
