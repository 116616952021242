import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const Breadcrumb = (props) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const navItems = props.data ? props.data : [];

  return (
    <nav id="breadcrumb" aria-label="breadcrumb" className="py-5">
      <ol className="breadcrumb">
        {pathnames.length > 0 ? (
          <li className="breadcrumb-item">
            <Link to="/">HOME</Link>
          </li>
        ) : (
          <li className="breadcrumb-item active">
            HOME
          </li>
        )}

        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          let breadcrumbName = '';
          if (name == 'beinbe' || name == 'buddy') {
            breadcrumbName = name
          }
          else {
            let breadcrumbValue = navItems.find(x => x.path.includes(name));
            breadcrumbName = breadcrumbValue ? breadcrumbValue.name : "...";
          }

          return isLast ? (
            <li key={index} className="breadcrumb-item active">
              {breadcrumbName.toUpperCase()}
            </li>
          ) : (
            <li key={index} className="breadcrumb-item">
              <Link to={routeTo}>{breadcrumbName.toUpperCase()}</Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
