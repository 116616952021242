import React, { useState, useEffect } from "react";

import { Navigation } from "./navigation";
import { Header } from "./header";
import { Explore } from "./explore";
import { BottomSection } from "./bottomSection";

export const HomePage = (props) => {
  const homeData = props.data ? props.data : {};
  const headerData = props.headerData ? props.headerData : {};
  const navData = props.navData ? props.navData : {};

  return (
    <div>
      <Navigation data={navData} />

      <Header data={headerData} />

      <Explore data={homeData} />

      <BottomSection navData={navData} contactInfo={props.contactInfo} />
    </div>
  );
};

