import React from 'react';
import { Breadcrumb } from './breadcrumb';
import { Navigation } from './navigation';
import { BottomSection } from './bottomSection';

export const Careers = (props) => {
  const mainPageImg = "img/careers/careers2.jpg";
  const howWeWorkImg = "img/careers/how-we-work.jpg";
  const lifeAtFansiImg = "img/careers/life-at-fansi.jpg";

  const componentData = props.data ? props.data : {}
  const navData = props.navData ? props.navData : {};

  return (
    <div>
      <Navigation data = {navData}/>

      <div id="career-header">
        <img className="" src={mainPageImg} alt="Fansi Group" />
      </div>

      <div id="career" className="pb-12">
        <div className="container">
          <Breadcrumb data = {navData}/>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 pt-5 md:pt-10 lg:pt-10 pb-5 md:pb-10 lg:pb-10">
            <div className="uppercase text-justify">
              <h2 className="mb-5 text-4xl font-bold text-[#333]">{componentData.CareersTitle}</h2>
            </div>
            <div className="text-justify">
              <h6>
                {componentData.CareersContent1}</h6>
              <br />
              <h6>
                {componentData.CareersContent2}
              </h6>
            </div>
          </div>

          <div className="grid gap-6 pt-12 md:grid-cols-2">
            <div className="">
              <h2 className="section-title-h2 uppercase">{componentData.CareersHeadLine1}</h2>
              <div>
                <img src={howWeWorkImg} className="h-[265px] w-full md:h-[380px] lg:h-[375px]" alt="" />
              </div>
              <div className="uppercase">
                <ul className="mt-6 md:mt-3 lg:mt-6">
                  <li className="text-center md:text-left">
                    <a href="/about" className="text-black underline hover:underline">
                    {componentData.AboutUsLink}
                    </a>
                  </li>
                  <li className="text-center md:text-left">
                    <a href="/creations" rel="nofollow" className="text-black underline hover:underline">
                    {componentData.CreationLink}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <h2 className="section-title-h2 uppercase">{componentData.CareersHeadLine2}</h2>
              <div>
                <img src={lifeAtFansiImg} className="h-[265px] w-full md:h-[380px] lg:h-[375px]" alt="" />{' '}
              </div>
              <div className="uppercase">
                <ul className="mt-6 md:mt-3 lg:mt-6">
                  <li className="text-center md:text-left">
                    <a href="/brands" rel="nofollow" className="text-black underline hover:underline">
                    {componentData.BrandLink}
                    </a>
                  </li>
                  {/* <li className="text-center md:text-left">
                    <a href="/releases" className="text-black underline hover:underline">
                    {componentData.ReleaseLink}
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomSection navData={navData} contactInfo={props.contactInfo}/>
    </div>
  );
};
