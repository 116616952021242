import React from 'react';
import { Breadcrumb } from './breadcrumb';
import { Navigation } from './navigation';
import { BottomSection } from './bottomSection';

export const Brands = (props) => {
  const brandData = props.data ? props.data : {}
  const navData = props.navData ? props.navData : {};

  return (
    <div>
      <Navigation data={navData} />
      <div id="brand-header" className="">
        <img className="hero" src="img/brands/brands.jpg" />
      </div>

      <div id="brand" className="pb-12">
        <div className="container">
          <Breadcrumb data={navData}/>

          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 pt-5 md:pt-10 lg:pt-15 pb-5 md:pb-10 lg:pb-18">
            <div className="uppercase">
              <h2 className="mb-5 text-4xl font-bold text-[#333]">{brandData.BrandTitle}</h2>
              <h3 className="text-2xl font-semibold text-[#333]">{brandData.BrandHeadLine}</h3>
            </div>
            <div className="text-justify">
              <h6>
                {brandData.BrandContent1}
              </h6>
              <br />
              <h6>
                {brandData.BrandContent2}
              </h6>
            </div>
          </div>

          <div className="pt-12">
            <h2 className="section-title-h2">{brandData.BrandTitle}</h2>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div className="">
                <a href="/brands/beinbe">
                  <div className='overflow-hidden'>
                    <img src="img/brands/brand-beinbe.png" className="transition duration-1000 hover:scale-105" alt="" />
                  </div>
                </a>
                <div className="caption"></div>
              </div>

              <div className="">
                <a href="/brands/buddy">
                  <div className='overflow-hidden'>
                    <img src="img/brands/brand-buddy.png" className="transition duration-1000 hover:scale-105" alt="" />
                  </div>
                </a>
                <div className="caption"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BottomSection navData={navData} contactInfo={props.contactInfo}/>
    </div>
  )
};
