import React, { useState } from 'react';
import Slider from 'react-slick';
import { cn } from '../utils/tw';
import { Breadcrumb } from './breadcrumb';
import { Navigation } from './navigation';
import { BottomSection } from './bottomSection';


export const Releases = (props) => {
  const [activeHlIdx, setActiveHlIdx] = useState(0);
  const [activeTabletIdx, setActiveTabletIdx] = useState(0);
  const [activeNewsIdx, setActiveNewsIdx] = useState(0);

  const componentData = props.data ? props.data : {}
  const navData = props.navData ? props.navData : {};

  const HIGHLIGHT = componentData.HightLight;
  const LATEST_NEWS = componentData.LatestNews;
  const RELATED_NEWS = componentData.RelatedNews;

  const hightLightSettingMobile = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIdx, newIdx) => setActiveHlIdx(newIdx),
    customPaging: (i) => (
      <div
        className={cn(`mt-4 h-1 w-full bg-[#D9D9D9] transition`, {
          'bg-[#949393]': i === activeHlIdx,
        })}
      />
    ),
  };

  const hightLightSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: (oldIdx, newIdx) => setActiveTabletIdx(newIdx),
    customPaging: (i) => (
      <div
        className={cn(`mt-4 h-1 w-full bg-[#D9D9D9] transition `, {
          'bg-[#949393]': i === activeTabletIdx
        })}
      />
    ),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      }
    ],
  };

  const SlideArrow = ({ type, className, currentSlide, slideCount, ...props }) => {
    return (
      <div
        {...props}
        className={cn(className, 'top-[140px] z-10', {
          'left-4': type === 'prev',
          'right-4': type === 'next',
        })}
      />
    );
  };

  const sliderSettingsNews = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '60px',
    prevArrow: <SlideArrow type="prev" />,
    nextArrow: <SlideArrow type="next" />,
    beforeChange: (oldIdx, newIdx) => setActiveNewsIdx(newIdx),
  };

  return (
    <div>
      <div id="release" className="pb-12">
        <Navigation data={navData} />

        <div id="release-header"><img className="hero" src="img/releases.jpg" alt='...' />
        </div>

        <div className="container">
          <Breadcrumb data={navData} />

          <div className="py-12">
            <h2 className="mb-5 text-center text-xl font-bold md:text-left md:text-3xl lg:text-4xl">{componentData.HightLightHeadline}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="hidden md:block col-span-1">
                <a href={HIGHLIGHT[0].url} target="_blank" rel="noreferrer" >
                  <div className='overflow-hidden'>
                    <img src={HIGHLIGHT[0].image} className="h-[200px] w-full md:h-[400px] lg:h-[300px]
                    transition duration-1000 hover:scale-105" alt="" />
                  </div>
                  <p className='text-center mt-5'>{HIGHLIGHT[0].description}</p>
                </a>
              </div>

              <div className="col-span-1 md:col-span-1 lg:col-span-2">
                <div className="hidden gap-6 overflow-hidden
                                md:grid md:grid-cols-1 md:overflow-visible
                                lg:grid lg:grid-cols-1 lg:overflow-visible">
                  <Slider {...hightLightSettings} className="h-fit">
                    {HIGHLIGHT.map((item, idx) => (
                      <div key={idx} className="lg:w-full px-2">
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <div className='overflow-hidden'>
                            <img src={item.image} alt="..."
                              className="h-[200px] w-full 
                            md:h-[282] md:w-full 
                            lg:w-full lg:h-[200px] 
                            transition duration-500 hover:scale-105" />
                          </div>
                          <div className="mt-5">
                            <p>{item.description}</p>
                          </div>
                        </a>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>

              <div className=" block md:hidden lg:hidden">
                <Slider {...hightLightSettingMobile} className="h-fit">
                  {HIGHLIGHT.map((item, idx) => (
                    <div key={idx} className="">
                      <div className="">
                        <img src={item.image} alt="..." className="aspect-[3/2] w-full " />
                        <div className="mt-6 text-center">
                          <a href={item.url} target="_blank" rel="noreferrer" >
                            <p>{item.description}</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>

          <div className="pb-12">
            <h2 className="mb-5 text-center text-xl font-bold md:text-left md:text-3xl lg:text-4xl">{componentData.HightLightLatest}</h2>
            <div className="hidden grid-cols-1 gap-6 md:grid md:grid-cols-2 lg:grid-cols-3 text-center" >
              {LATEST_NEWS.map((item, idx) => (
                <div key={idx}>
                  <div className="">
                    <div className="caption mt-2">
                      <a href={item.url} target="_blank" rel="noreferrer" >
                        <div className='overflow-hidden'>
                          <img src={item.image} alt="..." className="h-[300px] w-full  md:h-[200px] transition duration-1000 hover:scale-110" />
                        </div>
                        <p>{item.description}</p>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="block md:hidden">
              <Slider {...sliderSettingsNews} className="h-[350px]">
                {LATEST_NEWS.map((item, idx) => (
                  <div key={idx} className="px-2">
                    <div>
                      <img
                        src={item.image}
                        alt="..."
                        className={cn('h-[200px] w-full  transition-all', {
                          'h-[280px]': idx === activeNewsIdx,
                        })}
                      />
                      <div className="caption mt-2">
                        <a href={item.url} target="_blank" rel="noreferrer" >
                          <p className="line-clamp-2">{item.description}</p>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          <div className="pb-12">
            <h2 className="mb-5 text-center text-xl font-bold md:text-left md:text-3xl lg:text-4xl">{componentData.HightLightRelated}</h2>
            <ul className="">
              {RELATED_NEWS.map((item, idx) => (
                <li key={idx} className="border-b-[1px] border-[#D6D3D3] first-of-type:border-t-[1px]">
                  <a href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center justify-between gap-4 py-4 text-black"
                  >
                    <span>{item.title}</span>
                    <span className="flex-shrink-0">{item.created}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <BottomSection navData={navData} contactInfo={props.contactInfo} />
    </div>
  );
};
