import React, { useState, useEffect } from "react";
import { Contact } from "./contact";
import { Footer } from "./footer";

export const BottomSection = (props) => {
  const navData = props.navData ? props.navData : {};
  const contactInfo = props.contactInfo ? props.contactInfo : {};

  return (
    <div>
      <Contact data={contactInfo} />
      <Footer navData={navData} />
    </div>
  );
};
