import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { cn } from '../utils/tw';
import { useTranslation } from "react-i18next";

export const Explore = (props) => {
  const { i18n } = useTranslation();
  const [activeIdx, setActiveIdx] = useState(0);
  const [activeNewsIdx, setActiveNewsIdx] = useState(0);

  const newsData = props.data ? props.data.News : []
  let exploreTitle = props.data ? props.data.ExploreHeadLine : "...";
  let exploreTitleArr = exploreTitle.split("/");

  const newsSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: (oldIdx, newIdx) => setActiveIdx(newIdx),
    customPaging: (i) => (
      <div
        className={cn(`mt-4 h-1 w-full bg-[#D9D9D9] transition `, {
          'bg-[#949393]': i === activeIdx
        })}
      />
    ),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      }
    ],
  };

  const SlideArrow = ({ type, className, currentSlide, slideCount, ...props }) => {
    return (
      <div
        {...props}
        className={cn(className, 'top-[100px] z-10', {
          'left-4': type === 'prev',
          'right-4': type === 'next',
        })}
      />
    );
  };

  const mobileSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '60px',
    prevArrow: <SlideArrow type="prev" />,
    nextArrow: <SlideArrow type="next" />,
    beforeChange: (oldIdx, newIdx) => setActiveNewsIdx(newIdx)
  };

  return (
    <div id="explore" className="pb-12 pt-12 md:pt-8 lg:pt-[100px]">
      <div className="container">
        <div className="grid gap-6 md:grid-cols-3 lg:grid-cols-4">
          <div className="flex items-center md:col-span-3 lg:col-span-1">
            <div className="about-text -mb-1 w-full lg:mb-0 font-bold">
              <h2 className="block lg:hidden xl:hidden md:text-left text-xl md:text-3xl lg:text-4xl">{exploreTitleArr[0]} {exploreTitleArr[1]} </h2>
              <h2 className="hidden lg:block xl:block md:text-left text-xl md:text-3xl lg:text-4xl">{exploreTitleArr[0]} <br /> {exploreTitleArr[1]} </h2>
            </div>
          </div>

          {props.data && props.data.Explore ? props.data.Explore.map((d, i) => (
            <div key={'explore_' + i} className="card relative">
              <a href={d.route} className="page-scroll">
                <div className='overflow-hidden'>
                  <img src={d.imgUrl}
                    className="h-[180px] w-full xl:grayscale md:h-[400px] brightness-90  transition duration-1000 hover:filter-none hover:scale-105"
                  />
                </div>
              </a>
              <div className="absolute left-1/2 top-1/2 w-[70%] -translate-x-1/2 -translate-y-1/2 transform">
                <h4 className="text-center text-2xl font-bold uppercase text-white">{d.text}</h4>
              </div>
            </div>
          )) : ""}
        </div>

        {/* <div className="pt-12 md:pt-8 lg:pt-[100px]">
          <h2 className="font-bold text-xl md:text-3xl lg:text-4xl">{props.data ? props.data.NewsHeadLine : "..."}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="col-span-1">
              {<a href={newsData[0].url} target="_blank" rel="noreferrer">
                <div className='overflow-hidden'>
                  <img src={newsData[0].image} className="h-[200px] w-full md:h-[400px] lg:h-[300px] 
                  transition duration-1000 hover:scale-105" alt="" />
                </div>
                <p className="mt-5">{newsData[0].description}</p>
              </a>}
            </div>

            <div className="col-span-1 md:col-span-1 lg:col-span-2">
              <div className="hidden gap-6 overflow-hidden 
                              md:grid md:grid-cols-1 md:overflow-visible
                              lg:grid lg:grid-cols-1 lg:overflow-visible">
                <Slider {...newsSliderSettings} className="h-fit">
                  {newsData.slice(1).map((item, idx) => (
                    <div key={idx} className="lg:w-full px-2">
                      <a href={item.url} target="_blank" rel='noreferrer'>
                        <div className='overflow-hidden'>
                          <img src={item.image} alt="..."
                            className="h-[200px] w-full 
                            md:h-[282] md:w-full 
                            lg:w-full lg:h-[200px] 
                            transition duration-1000 hover:scale-105" />
                        </div>
                        <div className="mt-5">
                          <p>{item.description}</p>
                        </div>
                      </a>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>

            <div className="col-span-1 block md:hidden lg:hidden">
              <Slider {...mobileSliderSettings} className="h-fit">
                {newsData.map((item, idx) => (
                  <div key={idx} className="w-[75%] flex-shrink-0 px-2 md:w-[35%] lg:w-full">
                    <div className="">
                      <img src={item.image} alt="..."
                        className={cn('h-[200px] w-full  transition-all', {})}
                      />
                      <div className="caption">
                        <a href={item.url} target="_blank" rel="noreferrer" >
                          <p>{item.description}</p>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
