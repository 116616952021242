import React from 'react';
import { Breadcrumb } from './breadcrumb';
import { Navigation } from './navigation';
import { BottomSection } from './bottomSection';


export const Creations = (props) => {
  const creationData = props.data ? props.data : {}
  const navData = props.navData ? props.navData : {}

  return (
    <div>
      <Navigation data={navData} />

      <div id="creation-header" className="">
        <img className="hero" src="img/creations.jpg" alt="Fansi Group" />
      </div>

      <div id="creation" className="pb-10">
        <div className="container">
          <Breadcrumb data={navData} />

          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 pt-5 md:pt-10 lg:pt-10 pb-5">
            <div className="text-justify uppercase">
              <h2 className="mb-5 text-4xl font-bold text-[#333]">{creationData.CreationTitle}</h2>
              <h3 className="mb-5 text-2xl font-semibold text-[#333] w-full md:w-11/12 xl:w-7/12">
                {creationData.CreationHeadLine}
              </h3>
            </div>
            <div className="text-justify">
              <h6>
                {creationData.CreationContent1}
              </h6> <br />
              <h6>
                {creationData.CreationContent2}
              </h6>
            </div>
          </div>

          {/* <div className="pt-10">
            <h2 className="mb-5 text-center text-xl font-bold md:col-span-2 md:text-left md:text-3xl lg:text-4xl">
              {creationData.CreationNewsTitle}
            </h2>
            <div>
              <ul className="">
                {creationData.CreationNews.map((item, idx) => (
                  <li key={idx} className="border-b-[1px] border-[#D6D3D3] first-of-type:border-t-[1px]">
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center justify-between gap-4 py-4 text-black"
                    >
                      <span>{item.title}</span>
                      <span className="flex-shrink-0">{item.created}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div> */}
        </div>
      </div>

      <BottomSection navData={navData} contactInfo={props.contactInfo} />
    </div>
  );
};
