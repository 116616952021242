import React from 'react';

export const Header = (props) => {
  return (
    <div id="header">
      <div className="home-page-video">
        <div className="relative brightness-90 shadow-xl">
          <video autoPlay loop muted playsInline className="hero" poster='img/home-page.png'>
            <source src="img/home-page-video.mp4" type="video/mp4" />
          </video>
          <div className="absolute left-1/2 top-1/2 w-[70%] -translate-x-1/2 -translate-y-1/2 transform text-center text-3xl font-bold text-white lg:text-6xl">
            <h1 className='inline-block align-top font-svn-optima'>{props.data ? props.data.videoCaption : ""}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
