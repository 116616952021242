import React, { useState } from 'react';
import { Breadcrumb } from './breadcrumb';
import { Navigation } from './navigation';
import { BottomSection } from './bottomSection';
import Slider from 'react-slick';
import { cn } from '../utils/tw';

const PRODUCTS = [
  {
    image: 'img/products/bib1.png',
  },
  {
    image: 'img/products/bib2.png',
  },
  {
    image: 'img/products/bib3.png',
  },
  {
    image: 'img/products/bib4.png',
  },
  {
    image: 'img/products/bib5.png',
  },

];

export const Beinbe = (props) => {
  const componentData = props.data ? props.data : {}
  const navData = props.navData ? props.navData : {}

  const [activeIdx, setActiveIdx] = useState(0);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: (oldIdx, newIdx) => setActiveIdx(newIdx),
    customPaging: (i) => (
      <div
        className={cn(`mt-4 h-1 w-full bg-[#D9D9D9] transition`, {
          'bg-[#949393]': i === activeIdx,
        })}
      />
    ),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Navigation data={navData} />
      <div id="beinbe-header">
        <img className="hero" src="img/brands/banner-beinbe.png" alt="Fansi Group" />
      </div>

      <div id="beinbe" className="pb-12">
        <div className="container">
          <Breadcrumb data={navData} />
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 pt-5 md:pt-10 lg:pt-15 pb-5 md:pb-10 lg:pb-15">
            <div className="uppercase">
              <h2 className="mb-5 text-4xl font-bold text-[#333]">{componentData.BeinBeTitle}</h2>
              <h3 className="mb-5 text-2xl font-semibold text-[#333] ">{componentData.BeinBeHeadLine}</h3>
            </div>
            <div className="text-justify">
              <h6>
              <span className='font-semibold'>"Beinbe </span>{componentData.BeinBeContent1}
              </h6>
              <br />
              <h6>
              <span className='font-semibold'>Beinbe </span>{componentData.BeinBeContent2}
              </h6>
            </div>
          </div>

          {/* <div className="pt-12">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
              <div className="relative">
                <div className='overflow-hidden'>
                  <img src="img/brands/info1.jpg"
                    className="h-[180px] w-full md:h-[360px] lg:h-[520px] transition duration-1000 hover:scale-105" />
                </div>
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-white">
                  <h4 className="card-title text-center text-xl font-bold text-white md:text-4xl lg:text-6xl">2023</h4>
                  <h6 className="card-title text-nowrap text-center text-sm md:text-base lg:text-xl">
                    Created in 2023
                  </h6>
                </div>
              </div>

              <div className="relative">
                <div className='overflow-hidden'>
                  <img src="img/brands/info2.jpg"
                    className="h-[180px] w-full md:h-[360px] lg:h-[520px] transition duration-1000 hover:scale-105" />
                </div>
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-white">
                  <h4 className="card-title text-center text-xl font-bold text-white md:text-4xl lg:text-6xl">
                    22,000
                  </h4>
                  <h6 className="card-title text-nowrap text-center text-sm md:text-base lg:text-xl">
                    Products sold & counting
                  </h6>
                </div>
              </div>

              <div className="relative">
                <div className='overflow-hidden'>
                  <img src="img/brands/info3.jpg"
                    className="h-[180px] w-full md:h-[360px] lg:h-[520px] transition duration-1000 hover:scale-105" />
                </div>
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-white">
                  <h4 className="card-title text-center text-xl font-bold text-white  md:text-4xl lg:text-6xl">
                    22,000
                  </h4>
                  <h6 className="card-title text-nowrap text-center text-sm md:text-base lg:text-xl">
                    Products sold & counting
                  </h6>
                </div>
              </div>
            </div>
          </div> */}

          <div className="pt-12">
            <h2 className="section-title-h2">{componentData.BeinBeProduct}</h2>
            <div>
              <Slider {...sliderSettings} className="h-fit">
                {PRODUCTS.map((item, idx) => (
                  <div key={idx} className="w-full px-2">
                    <div className='overflow-hidden'>
                      <img src={item.image} alt="" className="aspect-square w-full" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <BottomSection navData={navData} contactInfo={props.contactInfo} />
    </div>
  );
};
