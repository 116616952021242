import { XMarkIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { cn } from '../utils/tw';

export const MobileNav = ({ items, open, onClose, addLanguage }) => {
  return (
    <div
      className={cn('fixed inset-0 block bg-white p-11 transition-all  md:hidden', {
        '-translate-x-full': !open,
      })}
    >
      <div className="mb-10 flex items-center justify-between">
        <a className="navbar-brand page-scroll " href="/">
          <img src="img/fansi-icon.png" alt="Fansi Group" className="w-[34px] md:w-[68px]" />
        </a>
        <button onClick={onClose}>
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>

      <ul>
        {items.map((item) => (
          <li key={item.path}>
            <a href={item.path} className="flex items-center justify-between border-b-[1px] border-black py-3">
              <span className='capitalize'>{item.name}</span>
              <ChevronRightIcon className="h-6 w-6" />
            </a>
          </li>
        ))}

      </ul>
    </div>
  );
};
