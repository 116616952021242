import emailjs from 'emailjs-com';
import React, { useEffect, useState } from 'react';

import { Navigation } from './navigation';
import { Footer } from './footer';
import { Alert, Button, Input } from "@material-tailwind/react";
import { MapPinIcon, PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline'

export const ContactPage = (props) => {
  const initialState = {
    name: "",
    email: "",
    message: "",
  };

  const [{ name, email, message }, setState] = useState(initialState);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const navData = props.navData ? props.navData : {};
  const componentData = props.data ? props.data : {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(name, email, message);

    emailjs
      .sendForm("service_0npsrsq", "template_jwkfdhs", e.target, "I65USaOnJheNFL0jR")
      .then(
        (result) => {
          setOpenConfirm(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <Navigation data={navData} />

      <div className="relative ">
        <img src="img/contact.png" className="object-cover" />
        <div className=" absolute left-1/2 top-1/2 w-[70%] -translate-x-1/2 -translate-y-1/2 transform text-center text-white">
          <h1 className='uppercase font-bold text-2xl md:text-3xl lg:text-6xl'>{componentData.ContactTitle}</h1>
          <p className='mt-5 leading-6 md:px-20 lg:px-56 text-sm md:text-xl lg:text-2xl italic'>{componentData.ContactHeadline}</p>
        </div>
      </div>

      <div
        id="contact-page"
        className="pt-8 md:pt-14 lg:pt-20 pb-10"
        style={{ backgroundColor: 'linear-gradient(to right, #6372ff 0%, #5ca9fb 100%)' }}>
        <div className="container">
          <div className="gap-8 grid grid-cols-1  md:grid-cols-3 lg:grid-cols-3 text-center">
            <div className="col-span-1">
              <span className="fa fa-map-marker fa-3x mb-3 mt-2"></span>
              <h2 className='text-2xl font-bold uppercase text-black mb-3'>{componentData.ContactVisit}</h2>
              <span className='italic  '>
                {props.data ? props.data.address : 'loading'}
              </span>
            </div>

            <div className="border-solid border-t-2 md:border-solid md:border-l-2 md:border-t-0 lg:border-solid lg:border-l-2 col-span-1 ">
              <span className="fa fa-phone fa-3x mb-3 mt-2"></span>
              <h2 className=' text-2xl font-bold uppercase text-black  mb-3'>{componentData.ContactCall}</h2>
              <span className='italic '>
                {props.data ? props.data.phone : 'loading'}
              </span>
            </div>

            <div className="border-solid border-t-2 md:border-solid md:border-l-2 md:border-t-0 lg:border-solid lg:border-l-2 col-span-1">
              <span className="fa fa-envelope fa-3x mb-3 mt-2"></span>
              <h2 className=' text-2xl font-bold uppercase text-black  mb-3'>{componentData.ContactMail}</h2>
              <span className='italic '>
                {props.data ? props.data.email : 'loading'}
              </span>
            </div>

            {/* <div className="mb-8 md:mb-10">
                <h2 className="section-title-h2">CONTACT US</h2>
                <p className="text-lg">
                  Please fill out the form below to send us an email and we will get back to you as soon as possible.
                </p>
              </div>
              
              <form name="sentMessage" onSubmit={handleSubmit}>
                <div className="grid grid-cols-4 gap-4">
                  <div className="form-group col-span-4 md:col-span-2">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="input w-full"
                      placeholder="Name"
                      required
                      onChange={handleChange}
                      value={name}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="form-group col-span-4 md:col-span-2">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="input w-full"
                      placeholder="Email"
                      required
                      onChange={handleChange}
                      value={email}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="form-group col-span-4">
                    <textarea
                      name="message"
                      id="message"
                      className="input w-full resize-none"
                      rows="3"
                      placeholder="Message"
                      required
                      onChange={handleChange}
                      value={message}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>

                  {openConfirm && (
                    <div className="form-group col-span-4">
                      <Alert open={openConfirm} onClose={() => {
                        setOpenConfirm(false);
                        clearState();
                      }} variant="ghost" >
                        <span className='italic'>Thank you <span className='font-bold'>{name}</span>! We will contact you soon.</span>
                      </Alert>
                    </div>)}

                  <div className="col-span-2">
                    <Button variant="filled"
                      type="submit"
                      className="">
                      SEND MESSAGE
                    </Button>
                  </div>
                </div>
              </form> */}


            {/* <div className="contact-info">
              <h3 className="text-2xl font-semibold">Contact Information</h3>
              <div className="grid grid-rows-3 items-center mt-5  ">
                <div className="flex flex-row">
                  <i className="fa fa-map-marker fa-2x"></i>
                  <span className='italic ml-5'>
                    {props.data ? props.data.address : 'loading'}
                  </span>
                </div>
                <div className="flex flex-row">
                  <i className="fa fa-phone fa-2x"></i>
                  <span className='italic ml-5'>
                    {props.data ? props.data.phone : 'loading'}
                  </span>
                </div>
                <div className="flex flex-row">
                  <i className="fa fa-envelope-o fa-2x"></i>
                  <span className='italic ml-5'>
                    {props.data ? props.data.email : 'loading'}
                  </span>

                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <Footer {...props} />
    </div>
  );
};
