import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';

import { ContactPage } from './components/contactPage';
import { About } from './components/about';
import { Careers } from './components/careers';
import { Brands } from './components/brands';
import { Creations } from './components/creations';
import { Releases } from './components/releases';
import { Beinbe } from './components/beInbe';
import { Buddy } from './components/buddy';
import { HomePage } from './components/homePage';
import { PrivatePolicy } from './components/policy';

import './App.css';

import JsonPageDataEN from './data/data-en.json';
import JsonPageDataVI from './data/data-vi.json';

import { useTranslation } from "react-i18next";

const NAV_ITEMS = [
  {
    path: '/about',
    name: 'About us',
  },
  {
    path: '/brands',
    name: 'Brands',
  },
  {
    path: '/creations',
    name: 'Creations',
  },
  // {
  //   path: '/releases',
  //   name: 'New Releases',
  // },
  {
    path: '/careers',
    name: 'Careers',
  },
];

const NAV_ITEMS_VI = [
  {
    path: '/about',
    name: 'Về chúng tôi',
  },
  {
    path: '/brands',
    name: 'Thương hiệu',
  },
  {
    path: '/creations',
    name: 'Sáng tạo',
  },
  // {
  //   path: '/releases',
  //   name: 'Tin tức',
  // },
  {
    path: '/careers',
    name: 'Tuyển dụng',
  },
];

const ROUTE_ITEMS = [
  {
    path: '/about',
    name: 'About us',
  },
  {
    path: '/brands',
    name: 'Brands',
  },
  {
    path: '/creations',
    name: 'Creations',
  },
  {
    path: '/releases',
    name: 'New Releases',
  },
  {
    path: '/careers',
    name: 'Careers',
  },
  {
    path: '/private-policy',
    name: 'private-policy',
  },
];

const App = () => {
  const [landingPageData, setLandingPageData] = useState(JsonPageDataEN);
  const [landingPageDataVI, setLandingPageDataVI] = useState(JsonPageDataVI);
  const { i18n } = useTranslation();

  const localStorageLanguage = localStorage.getItem('SITE_LANGUAGE');
  const currentLanguage = (localStorageLanguage != null && (localStorageLanguage == 'en' || localStorageLanguage == 'vi')) ? localStorageLanguage : "en"; //i18n.language;
  const navData = currentLanguage == 'en' ? NAV_ITEMS : NAV_ITEMS_VI;
  const contactInfoData = currentLanguage == 'en' ? landingPageData.ContactSection : landingPageDataVI.ContactSection

  const routes = [
    {
      path: '/', breadcrumb: 'Home', component: HomePage,
      props: {
        data: currentLanguage == 'en' ? landingPageData.ExploreNews : landingPageDataVI.ExploreNews,
        headerData: currentLanguage == 'en' ? landingPageData.Header : landingPageDataVI.Header,
        contactInfo: contactInfoData,
        navData: navData
      }
    },
    {
      path: '/about', breadcrumb: 'About', component: About,
      props:
      {
        data: currentLanguage == 'en' ? landingPageData.About : landingPageDataVI.About,
        navData: navData,
        contactInfo: contactInfoData,
      }
    },
    {
      path: '/brands', breadcrumb: 'Brands', component: Brands,
      props:
      {
        data: currentLanguage == 'en' ? landingPageData.Brands : landingPageDataVI.Brands,
        navData: navData,
        contactInfo: contactInfoData,
      }
    },
    {
      path: '/creations', breadcrumb: 'Creations', component: Creations,
      props: {
        data: currentLanguage == 'en' ? landingPageData.Creations : landingPageDataVI.Creations,
        navData: navData,
        contactInfo: contactInfoData,
      }
    },
    {
      path: '/releases', breadcrumb: 'New Releases', component: Releases,
      props: {
        data: currentLanguage == 'en' ? landingPageData.Releases : landingPageDataVI.Releases,
        navData: navData,
        contactInfo: contactInfoData,
      }
    },
    {
      path: '/careers', breadcrumb: 'Careers', component: Careers,
      props: {
        data: currentLanguage == 'en' ? landingPageData.Careers : landingPageDataVI.Careers,
        navData: navData,
        contactInfo: contactInfoData,
      }
    },
    {
      path: '/contact', breadcrumb: 'Contact Page', component: ContactPage,
      props: {
        data: currentLanguage == 'en' ? landingPageData.ContactPage : landingPageDataVI.ContactPage,
        navData: navData
      }
    },
    {
      path: '/private-policy', component: PrivatePolicy,
      props: {
        data: currentLanguage == 'en' ? landingPageData.Careers : landingPageDataVI.Careers,
        navData: navData,
        routeData: ROUTE_ITEMS,
        contactInfo: contactInfoData,
      }
    },
  ];

  return (
    <div>
      <Router>
        <div>
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} exact element={<route.component {...route.props} />} />
            ))}
            <Route path='/brands' exact element={<Outlet />} >
              <Route path="/brands/beinbe" exact
                element={<Beinbe
                  data={currentLanguage == 'en' ? landingPageData.BeInBe : landingPageDataVI.BeInBe}
                  navData={navData} contactInfo={contactInfoData} />}
              />
              <Route path="/brands/buddy" exact
                element={<Buddy
                  data={currentLanguage == 'en' ? landingPageData.Buddy : landingPageDataVI.Buddy}
                  navData={navData} contactInfo={contactInfoData} />}
              />
            </Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
};

export default App;
