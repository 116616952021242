import { useState } from 'react';
import React from 'react';

export const Footer = (props) => {
  const NAV_ITEMS = props.navData ? props.navData : {};

  const localStorageLang = localStorage.getItem('SITE_LANGUAGE');
  const currentLang = (localStorageLang != null && (localStorageLang === 'en' || localStorageLang === 'vi')) ? localStorageLang : "en"; //i18n.language;
  
  return (
    <div id="footer-section">
      <div id="footer-menu-section" className="relative py-7">
        <div className="container">
          <div className="">
            <div className="col-12 col-lg-12">
              <img className="footer-logo" src="img/fansi.png" alt="Fansi Group" />
            </div>
          </div>
          <div className="">
            <div className="mt-6">
              <ul>
                {NAV_ITEMS.map((item) => (
                  <li key={item.path} className="block py-2 capitalize w-6/12" >
                    <a href={item.path} >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 right-0 top-2">
          <img className="h-full w-auto opacity-20" src="img/fansi-icon.png" alt="Fansi Group" />
        </div>
      </div>

      <div id="bottom-page" className="container py-6">
        <div className="flex flex-col items-center justify-between gap-6 md:flex-row">
          <div className="">
            <ul className="flex flex-wrap justify-center gap-4 md:justify-start">
              {/* <li>
                <a href="/private-policy" className="nav-link">
                  Term of use
                </a>
              </li> */}
              <li>
                <a href="/private-policy" className="nav-link">
                  {currentLang === 'en' ? 'Privacy Policy' : 'Chính sách quyền riêng tư'}
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="social text-center ">
            <ul className="flex flex-nowrap">
              <li>
                <a href={props.data ? props.data.facebook : '/'} target="_blank">
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href={props.data ? props.data.twitter : '/'} target="_blank">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href={props.data ? props.data.youtube : '/'} target="_blank">
                  <i className="fa fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};
