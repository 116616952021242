import React from 'react';

export const Contact = (props) => {
  const componentData = props.data ? props.data : {}

  return (
    <div id="contact-section" className="py-10 md:py-[80px] lg:py-[100px] bg-[#f6f6f6]">
      <div className="container">
        <div className="flex flex-col lg:flex-row items-center justify-between gap-5">
          <div className="about-text">
            <h3 className="text-center lg:text-left
            text-base md:text-xl lg:text-xl 
            font-semibold text-[#333] ">
              {componentData.ContactTitle}
            </h3>
          </div>
          
          <div className="flex gap-5 text-xs md:text-sm lg:text-lg">
            <a href="/careers" className="btn btn-custom-contact btn-lg page-scroll px-4">
              {componentData.Join}
            </a>
            <a href="/contact" className="btn btn-custom-contact btn-lg page-scroll px-4">
              {componentData.Reach}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
